import React from 'react';

const OtpAuthentication = ({ _this }) => {
  return (
    <div className="w-96 sm:w-[30rem] mx-2 backdrop-blur-xs bg-white/30 p-2 rounded-2xl flex flex-col items-center gap-4 text-white">
      <div>
        <div className="flex flex-col gap-4 items-center p-10 h-[65%]">
          <p className="text-xl font-bold">OTP Authentication</p>
          <div className="my-2 flex flex-col items-center justify-center gap-4">
            <p>
              For security purposes, please enter the 6-digit code sent to your email to proceed
              with your login.
            </p>
            <div className="">
              <label className="block text-base mb-2 text-white">Enter OTP</label>
              <input
                className="py-2 text-md outline-none bg-inherit focus:bg-inherit active:bg-inherit w-64 sm:w-[20rem] border-white text-white rounded-full caret-white"
                id="authnetication Code"
                placeholder="Enter Authentication Code"
                type="number"
                value={_this.employeeAuthenticationDetails.otp}
                onChange={(e) =>
                  _this.setEmployeeAuthenticationDetails((prev) => ({
                    ...prev,
                    otp: e.target.value
                  }))
                }
              />
            </div>

            <button
              onClick={() => {
                _this.validateOtp();
              }}
              // style="bg-inherit hover:bg-transparent"
              // color="warning"
              className={`bg-button-primary hover:bg-button-primaryHover text-white border-none rounded-full px-4 py-1 ${
                _this.authenticationCode.length > 0 ? 'cursor-pointer' : 'cursor-not-allowed'
              }`}
              disabled={_this.employeeAuthenticationDetails.otp?.length > 0 ? false : true}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpAuthentication;
