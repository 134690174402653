import { handleResponse, API } from './utils';

const auth = {
  checkUserExistsAndComparePassword: async (data) => {
    let response = null;
    try {
      response = await API.post('/v1/auth/check-user-exists-and-compare-password', {
        email: data.email,
        password: data.password || null
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  customerLogin: async (user_id) => {
    let response = null;
    try {
      response = await API.get(`/v1/auth/customer-login?user_id=${user_id}`);
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  createEmployeeAuthentication: async (data) => {
    let response = null;
    try {
      response = await API.post(`/v1/auth/create-employee-authentication`, data);
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  employeeLogin: async (data) => {
    let response = null;
    try {
      response = await API.post(`/v1/auth/employee-login`, {
        user: data.user,
        email: data.email,
        authentication_type: data.authentication_type
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },

  validateEmployeOtpOrAuthenticationCode: async (data) => {
    let response = null;
    try {
      response = await API.post(`/v1/auth/validate-employee-otp-or-authentication-code`, data);
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  login: async (data) => {
    let response = null;
    try {
      response = await API.post('/v1/auth/login', {
        email: data.email,
        password: data.password || null,
        otp: data.otp || null,
        ...data
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  loginByToken: async (email, refresh_token) => {
    let response = null;
    await API.get('/v1/auth/login-by-token/' + email.toLowerCase() + '/' + refresh_token)
      .then(function (res) {
        response = res;
      })
      .catch(function (error) {
        response = error;
      });
    return handleResponse(response);
  },
  sendOTP: async (email) => {
    let response = null;
    try {
      response = await API.post('/v1/auth/send-otp', {
        email: email
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  verifyOTP: async (email, otp) => {
    let response = null;
    try {
      response = await API.post('/v1/auth/verify-otp', {
        email: email,
        otp: otp
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  resetPassword: async (email, password, confirm_password) => {
    let response = null;
    try {
      response = await API.post('/v1/auth/reset-password', {
        email: email,
        password: password,
        confirm_password: confirm_password
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  forgetPassword: async (email) => {
    let response = null;
    try {
      response = await API.post('/v1/auth/forget-password', {
        email: email
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  }
};

export default auth;
