import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//import { Header, Footer } from 'components';
import { loadingStart, loadingStop, login } from 'redux/action';
import { toast } from 'react-toastify';
import Body from './Body';
import API from 'services/api';
import { useOnKeyPress } from 'services/helpers';
import { ProgressBar } from 'react-bootstrap';

const Index = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [forgetPasswordModalVisibility, setForgetPasswordModalVisibility] = useState(false);
  const [forgetPasswordEmail, setForgetPasswordEmail] = useState('');
  const [changePasswordModalVisibility, setChangePasswordModalVisibility] = useState(false);
  const [otp, setOTP] = useState('');
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [steps, setSteps] = useState(0);
  const [enableTwoFactorAuthentication, setEnableTwoFactorAuthentication] = useState(null);
  const [validateTwoFactorAuthentication, setValidateTwoFactorAuthentication] = useState(null);
  const [authenticationCode, setAuthenticationCode] = useState('');

  const [selectAccountsModalVisibility, setSelectAccountsModalVisisbility] = useState(false);
  const [customerAccountsModalDetails, setCustomerAccountsModalDetails] = useState(null);

  const [chooseAuthenticationModalVisbility, setChooseAuthenticationModalVisibility] =
    useState(false);
  const [chooseAuthenticationModalDetails, setChooseAuthenticationModalDetails] = useState(null);
  const [employeeAuthenticationDetails, setEmployeeAuthenticationDetails] = useState(null);
  const [sendingOtpMailProgress, setSendingOtpMailProgress] = useState(false);
  const navigate = useNavigate();

  const onLogin = async () => {
    if (!email) return toast.error('Please provide Email');
    if (!password) return toast.error('Please enter Password');
    dispatch(loadingStart());
    await API.checkUserExistsAndComparePassword({ email, password })
      .then((response) => {
        if (response) {
          if (response.user_role == 'customer') {
            if (response.numberOfAccounts > 1) {
              setSelectAccountsModalVisisbility(true);
              setCustomerAccountsModalDetails(response);
            } else {
              onCustomerLogin(response.accountDetails[0]?.user_id);
            }
          } else {
            if (response.is_authenticated == false) {
              setChooseAuthenticationModalVisibility(true);
              setChooseAuthenticationModalDetails(response);
            } else {
              onEmployeeLogin(response.employee_authentication);
              // setSteps(1);
              // setEmployeeAuthenticationDetails(response.employee_authentication);
            }
          }
          // if (response.role == 'Customer') {
          //   response.token_expiry = new Date().getTime() + 23 * 60 * 60 * 1000;
          //   dispatch(login(response));
          //   toast.success('Hellooo! Welcome to MyHspp Dashboard');
          //   navigate('/customer-dashboard');
          // } else {
          //   setSteps(1);
          //   if (response.qrCode) {
          //     setEnableTwoFactorAuthentication(response);
          //   } else {
          //     setValidateTwoFactorAuthentication(response);
          //   }
          // }
        }
        // dispatch(loadingStop());
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const onCustomerLogin = async (user_id) => {
    dispatch(loadingStart());
    await API.customerLogin(user_id)
      .then((response) => {
        if (response) {
          setSelectAccountsModalVisisbility(false);
          setCustomerAccountsModalDetails(null);
          response.token_expiry = new Date().getTime() + 23 * 60 * 60 * 1000;
          dispatch(login(response));
          toast.success('Hellooo! Welcome to MyHspp Dashboard');
          navigate('/customer-dashboard');
          // navigate(`/customer-info/${response.customer_mongo_id}`);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const onEmployeeLogin = async (data) => {
    if (data.authentication_type == 'OTP') setSendingOtpMailProgress(true);
    dispatch(loadingStart());
    await API.employeeLogin(data)
      .then((response) => {
        if (response) {
          setSteps(1);
          setEmployeeAuthenticationDetails(response);
          setSendingOtpMailProgress(false);
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  // const onLogin = async () => {
  //   if (!email) return toast.error('Please provide Email');
  //   if (!password) return toast.error('Please enter Password');
  //   dispatch(loadingStart());
  //   await API.login({ email, password })
  //     .then((response) => {
  //       if (response) {
  //         if (response.role == 'Customer') {
  //           response.token_expiry = new Date().getTime() + 23 * 60 * 60 * 1000;
  //           dispatch(login(response));
  //           toast.success('Hellooo! Welcome to MyHspp Dashboard');
  //           navigate('/customer-dashboard');
  //         } else {
  //           setSteps(1);
  //           if (response.qrCode) {
  //             setEnableTwoFactorAuthentication(response);
  //           } else {
  //             setValidateTwoFactorAuthentication(response);
  //           }
  //         }
  //       }
  //     })
  //     .finally(() => {
  //       dispatch(loadingStop());
  //     });
  // };

  const validateOtp = async () => {
    let userData;
    if (employeeAuthenticationDetails.authentication_type == 'OTP') {
      userData = {
        user_id: employeeAuthenticationDetails.user,
        email: employeeAuthenticationDetails.email,
        authentication_type: employeeAuthenticationDetails.authentication_type,
        otp: employeeAuthenticationDetails.otp
      };
    } else {
      userData = {
        user_id: employeeAuthenticationDetails.user,
        email: employeeAuthenticationDetails.email,
        authentication_type: employeeAuthenticationDetails.authentication_type,
        authenticationCode: employeeAuthenticationDetails.authenticationCode
      };
    }

    dispatch(loadingStart());
    await API.validateEmployeOtpOrAuthenticationCode(userData)
      .then((response) => {
        if (response) {
          response.token_expiry = new Date().getTime() + 23 * 60 * 60 * 1000;
          dispatch(login(response));
          toast.success('Hellooo! Welcome to MyHspp Dashboard');
          navigate('/dashboard');
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const handelOTPSend = async () => {
    if (forgetPasswordEmail == '') return toast.error('Email cannot be blank.');
    dispatch(loadingStart());
    await API.sendOTP(forgetPasswordEmail)
      .then((response) => {
        if (response) {
          if (!isButtonDisabled) setButtonDisabled(true);
          toast.success('OTP Send !');
        }
      })
      .finally(() => {
        dispatch(loadingStop());
      });
  };

  const handleForgetPasswordSubmit = async () => {
    if (forgetPasswordEmail == '' && otp == '') return toast.error('Email or OTP cannot be blank.');
    dispatch(loadingStart());
    await API.verifyOTP(forgetPasswordEmail, otp)
      .then((response) => {
        if (response) {
          toast.success('OTP Verified');
          setChangePasswordModalVisibility(true);
        }
      })
      .finally(() => {
        setForgetPasswordModalVisibility(false);
        // setForgetPasswordEmail('');
        setOTP('');
        dispatch(loadingStop());
      });
  };
  const handelChangePasswordSubmit = async () => {
    if (forgetPasswordEmail == '' || newPassword == '' || confirmNewPassword == '')
      return toast.error('Fill all the mandatory fields.');
    if (newPassword !== confirmNewPassword) return toast.error('Password Do Not Match.');
    dispatch(loadingStart());
    await API.resetPassword(forgetPasswordEmail, newPassword, confirmNewPassword)
      .then((response) => {
        if (response) {
          toast.success('Password Reset Successfully.');
        }
      })
      .finally(() => {
        setChangePasswordModalVisibility(false);
        setForgetPasswordEmail('');
        setOTP('');
        setNewPassword('');
        setConfirmNewPassword('');
        dispatch(loadingStop());
      });
  };

  const customerSelectAccountSubmit = (user) => {
    if (user.status == 'Deleted') return toast.error('This account is deleted cannot login.');
    onCustomerLogin(user.user_id);
  };

  const chooseAuthenticationSystemSubmit = async (authentication_type) => {
    dispatch(loadingStart());
    await API.createEmployeeAuthentication({
      user_id: chooseAuthenticationModalDetails._id,
      authentication_type: authentication_type,
      email: chooseAuthenticationModalDetails.email
    })
      .then((response) => {
        if (response) {
          onEmployeeLogin(response);
        }
      })
      .finally(() => {
        setChooseAuthenticationModalVisibility(false);
        setChooseAuthenticationModalDetails(null);
        dispatch(loadingStop());
      });
  };
  const handleKeyPress = () => {
    let onKeypress = null;
    if (forgetPasswordModalVisibility) {
      onKeypress = handleForgetPasswordSubmit();
    } else if (employeeAuthenticationDetails) {
      onKeypress = validateOtp();
    } else {
      onKeypress = onLogin();
    }

    return onKeypress;
  };
  useOnKeyPress(handleKeyPress, 'Enter');
  return (
    <>
      <Body
        _this={{
          email,
          setEmail,
          password,
          setPassword,
          rememberMe,
          setRememberMe,
          onLogin,
          forgetPasswordModalVisibility,
          setForgetPasswordModalVisibility,
          changePasswordModalVisibility,
          setChangePasswordModalVisibility,
          forgetPasswordEmail,
          setForgetPasswordEmail,
          handleForgetPasswordSubmit,
          otp,
          setOTP,
          handelOTPSend,
          newPassword,
          setNewPassword,
          confirmNewPassword,
          setConfirmNewPassword,
          handelChangePasswordSubmit,
          isButtonDisabled,
          steps,
          setSteps,
          validateOtp,
          enableTwoFactorAuthentication,
          validateTwoFactorAuthentication,
          authenticationCode,
          setAuthenticationCode,

          //for customers login
          selectAccountsModalVisibility,
          setSelectAccountsModalVisisbility,
          customerAccountsModalDetails,
          customerSelectAccountSubmit,

          //for employees login
          chooseAuthenticationModalVisbility,
          setChooseAuthenticationModalVisibility,
          chooseAuthenticationModalDetails,
          chooseAuthenticationSystemSubmit,
          employeeAuthenticationDetails,
          setEmployeeAuthenticationDetails,
          sendingOtpMailProgress
        }}
      />
    </>
  );
};

export default Index;
