import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Card, Button, Input } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';
import Form from 'react-bootstrap/Form';
import { parseHsppId, renderStatusColorClass } from 'services/helpers';

import ButtonBTN from 'react-bootstrap/Button';
const SelectAccountsModal = ({ _this }) => {
  return (
    <Modal
      style={{ zIndex: 9999 }}
      show={_this.selectAccountsModalVisibility}
      onHide={() => _this.setSelectAccountsModalVisisbility(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={'static'}
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Select Account({_this.customerAccountsModalDetails?.numberOfAccounts})
        </Modal.Title>
        <AiFillCloseCircle
          size={30}
          onClick={() => _this.setSelectAccountsModalVisisbility(false)}
        />
      </Modal.Header>
      <Modal.Body className="flex flex-row">
        <Card className="">
          <div className="text-md text-slate-500 mt-1 flex flex-col gap-4">
            {_this.customerAccountsModalDetails?.accountDetails?.map((item, index) => (
              <div
                className="bg-slate-100 px-3 py-2 rounded flex flex-col gap-2 cursor-pointer"
                key={index}
                onClick={() => _this.customerSelectAccountSubmit(item)}
              >
                <div className="flex justify-between items-center">
                  <div className="leading-tight">
                    <p className="font-bold text-lg text-slate-600">{item.customer_name}</p>
                    <p className="">({item.user_name})</p>
                  </div>

                  <div className="flex flex-row items-center justify-between">
                    <span
                      className={`${renderStatusColorClass(
                        item?.status === 'Active' ? 'Active' : 'Cancelled'
                      )} font-semibold rounded text-xs`}
                    >
                      <span className="font-bold text-sm uppercase">{item.status}</span>
                    </span>
                  </div>
                </div>

                <div className="flex justify-between items-center">
                  <p>{item.email}</p>
                  <p className="font-bold text-sm text-slate-600">{item.customer_id}</p>
                </div>
              </div>
            ))}
          </div>
        </Card>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button
          onClick={() => {
            // _this.handelChangePasswordSubmit();
          }}
        >
          Change Password
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default SelectAccountsModal;
