import React from 'react';
import { Card, Button } from 'components';
import { parseHsppId, renderStatusColorClass } from 'services/helpers';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
import { Table } from 'components';
import { Link } from 'react-router-dom';
import ViewSubscriptionDetails from './ViewSubscriptionDetails';
import { Profile } from 'components';
import SetPrimaryPaymentModal from './SetPrimaryPaymentModal';
import AddNewPayment from './AddNewPayment';
import { BiPlus } from 'react-icons/bi';
import DocumentModel from './DocumentModel';
import { PermissionWrapper, HasPermission } from 'components';

const Section = ({ _this }) => {
  const customer = _this.customerDetails;

  const tooltipUI = (row) => {
    if (row.original.status === 'Failed') {
      return (
        <div>
          {JSON.parse(row.original?.payment_gateway_response)?.last_payment_error?.message ||
            JSON.parse(row.original?.payment_gateway_response)?.message}
        </div>
      );
    } else if (row.original.status === 'Refunded') {
      const info = row.original?.refunded_data?.map((item, i) => {
        return (
          <div key={i}>
            <p>
              {moment(item.refunded_on).format('YYYY-MM-DD')} | ${item.amount} | {item.status}
            </p>
            <p>--{item.reason ? item.reason : ''}--</p>
          </div>
        );
      });
      return info;
    }

    return '';
  };

  const invoice_table_columns = [
    {
      Header: 'Invoice ID',
      Cell: (row) => <div>#{parseHsppId(row.original._id)}</div>
    },
    {
      Header: 'Transaction ID',
      minWidth: 200,
      Cell: (row) =>
        row.original.payment_gateway_transaction_id && (
          <div>{row.original.payment_gateway_transaction_id}</div>
        )
    },
    {
      accessor: 'due_date',
      Header: 'Due Date',
      Cell: (row) => (
        <div>{row.original.due_date && moment(row.original.due_date).format('YYYY-MM-DD')}</div>
      )
    },
    {
      accessor: 'amount',
      Header: 'Amount',
      Cell: (row) => <div>${row.original.amount}</div>
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: (row) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-bottom ">{tooltipUI(row)}</Tooltip>}
        >
          {({ ref, ...triggerHandler }) => (
            <div
              {...triggerHandler}
              ref={ref}
              className={`font-semibold px-3 py-1 rounded text-xs ${renderStatusColorClass(
                row.original.status === 'Paid'
                  ? 'Active'
                  : row.original.status === 'Failed'
                  ? 'Expired'
                  : row.original.status === 'Cancel'
                  ? 'Cancelled'
                  : row.original.status === 'Refunded'
                  ? 'Cancelled'
                  : 'OnHold'
              )}`}
            >
              {row.original.status === 'Paid'
                ? 'PAID'
                : row.original.status === 'Failed'
                ? 'FAILED'
                : row.original.status === 'Cancel'
                ? 'CANCELLED'
                : row.original.status === 'Processing'
                ? 'PROCESSING'
                : row.original.status === 'Refunded'
                ? 'REFUNDED'
                : 'PENDING'}
            </div>
          )}
        </OverlayTrigger>
      )
    },
    {
      accessor: 'action',
      Header: 'Bill',
      Cell: (row) => (
        <center>
          <Link
            to={`/view-invoice/${row.original._id}`}
            target="_blank"
            className="bg-blue-500 text-white px-2 py-1 rounded text-xs flex flex-row self-center w-20 justify-center items-center"
          >
            View Bill
          </Link>
        </center>
      )
    }
  ];

  const checkIfPasswordChangedgreaterThan120Days = () => {
    const passwordChangeDate = moment(_this.user?.password_changed_on);
    const currentDate = moment();

    // Check if the password was changed more than 120 days ago
    const diffInDays = currentDate.diff(passwordChangeDate, 'days');

    let result;

    if (diffInDays > 120) {
      result = true;
    } else {
      result = false;
    }

    return result;
  };
  return (
    <>
      {_this.user?.is_password_changed == false || checkIfPasswordChangedgreaterThan120Days() ? (
        <Profile
          customer={customer}
          is_password_changed={_this.user?.is_password_changed}
          password_changed_greater_than_120_days={checkIfPasswordChangedgreaterThan120Days()}
        />
      ) : (
        <>
          <Alert key={'success'} variant={'success'}>
            Welcome{' '}
            <strong>
              {_this?.customerDetails?.first_name} {_this?.customerDetails?.last_name}
            </strong>{' '}
            to Your Dashboard
          </Alert>
          <Card background={'bg-cardbg1'}>
            <div className="flex flex-row justify-between">
              <h3 className="text-body-color text-xl md:text-2xl">Customer Overview</h3>
              <div className="mr-10">
                <div className="flex flex-row items-center justify-between">
                  <span
                    className={`${renderStatusColorClass(
                      customer?.status === 'Active' ? 'Active' : 'Cancelled'
                    )} font-bold rounded text-xl`}
                  >
                    <span className="uppercase">{customer?.status}</span>
                  </span>
                </div>
              </div>
            </div>
          </Card>
          <div className="flex flex-col md:flex-row">
            <Card className="mb-3 flex basis-1/2 mr-2 mt-2">
              <div className="pb-2 flex flex-row border-b-[3px] items-center justify-between">
                <h3 className="text-body-color text-xl font-semibold">
                  Customer | {customer?.first_name + ' ' + customer?.last_name}
                </h3>
              </div>
              <h6 className="text-body-color font-medium border-b-[2px] pb-1 mt-3">
                Contact Details
              </h6>
              <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
                <h5 className="text-slate-500 text-sm">HSPP ID:</h5>
                <h5 className="text-slate-500 text-sm">{parseHsppId(customer?._id)}</h5>
              </div>
              <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
                <h5 className="text-slate-500 text-sm">Patient ID:</h5>
                <h5 className="text-slate-500 text-sm">{customer?.customer_id}</h5>
              </div>
              <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
                <h5 className="text-slate-500 text-sm">Name:</h5>
                <h5 className="text-slate-500 text-sm">
                  {customer?.first_name + ' ' + customer?.last_name}
                </h5>
              </div>
              <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
                <h5 className="text-slate-500 text-sm">Phone:</h5>
                <h5 className="text-slate-500 text-sm">{customer?.phone}</h5>
              </div>
              <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
                <h5 className="text-slate-500 text-sm">Email:</h5>
                <h5 className="text-slate-500 text-sm">{customer?.email}</h5>
              </div>
              <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
                <h5 className="text-slate-500 text-sm">Signature:</h5>
                <h5 className="text-slate-500 text-sm signature">
                  {customer?.signature?.signature}
                </h5>
              </div>
              <div className="mt-3 flex justify-between items-center border-b-[1px] pb-2">
                <h5 className="text-slate-500 text-sm">Documents:</h5>
                <button
                  className="bg-blue-500 text-white px-2 py-1 rounded-md"
                  onClick={() => {
                    _this.setDocumentModelOpen(true);
                  }}
                >
                  Manage Documents
                </button>
              </div>
            </Card>
            <Card className="mb-3 flex basis-1/2 mt-2">
              <h3 className="text-body-color text-xl font-semibold border-b-[3px] pb-2">
                Shipping & Billing Information
              </h3>
              <h6 className="text-body-color font-medium border-b-[2px] pb-1 mt-3">
                Shipping Address
              </h6>
              <div className="text-md text-slate-500 mt-1 w-[300px]">
                {customer?.shipping_address.contact}
                <br />
                {customer?.shipping_address.address1 +
                  ', ' +
                  (customer?.shipping_address.address2
                    ? customer?.shipping_address.address2 + ', '
                    : '') +
                  customer?.shipping_address.city +
                  ', ' +
                  customer?.shipping_address.state +
                  ' ' +
                  customer?.shipping_address.zipcode +
                  ', ' +
                  customer?.shipping_address.country}
              </div>
              <h6 className="text-body-color font-medium border-b-[2px] pb-1 mt-3">
                Billing Address
              </h6>
              <div className="text-md text-slate-500 mt-1 w-[300px]">
                {customer?.billing_address.address1 +
                  ', ' +
                  (customer?.billing_address.address2
                    ? customer?.billing_address.address2 + ', '
                    : '') +
                  customer?.billing_address.city +
                  ', ' +
                  customer?.billing_address.state +
                  ' ' +
                  customer?.billing_address.zipcode +
                  ', ' +
                  customer?.billing_address.country}
              </div>
            </Card>
          </div>
          <div className="flex flex-col md:flex-row">
            <Card className="mb-3 flex basis-1/3 mr-2 mt-2">
              <div className="pb-2 flex flex-row border-b-[3px] items-center justify-between">
                <h3 className="text-body-color text-xl font-semibold  pb-2">Payment Method</h3>
                <button
                  onClick={() => {
                    _this.setAddPaymentModalOpen(true);
                  }}
                  className="bg-blue-500 text-green-700 px-2 py-1 rounded text-xs flex flex-row"
                >
                  <BiPlus className="text-white" size={23} />
                  <span className="font-medium text-base ml-0.5 text-white">Add</span>
                </button>
              </div>

              {customer?.payment_method ? (
                <>
                  {customer?.payment_method?.map((item, index) => (
                    <div
                      onClick={() => {
                        if (!_this.user?.permissions?.includes('set_primary_payment_method'))
                          return false;

                        if (item.is_primary == false) {
                          _this.setSelectPrimaryPaymentModalOpen(true);
                          item.payment_method == 'creditcard'
                            ? _this.setSelectedPaymentDetails((prev) => ({
                                ...prev,
                                payment_method_id: item._id,
                                customer_id: item.customer,
                                card_number: item.card_details.card_last_4_digit,
                                payment_method: item.payment_method
                              }))
                            : _this.setSelectedPaymentDetails((prev) => ({
                                ...prev,
                                payment_method_id: item._id,
                                customer_id: item.customer,
                                card_number: item.ach_details.account_last_4_digit,
                                payment_method: item.payment_method
                              }));
                        }
                      }}
                      key={index}
                      className={`p-3 mt-3 flex flex-col justify-center rounded-lg items-end border-2 border-blue-500 ${
                        item.is_primary == false && 'border'
                      } `}
                    >
                      <div className="flex justify-between items-center w-full mb-1">
                        <p className="text-sm">Payment Type:</p>
                        <p className="text-body-color text-sm">
                          {item.payment_method === 'creditcard' ? 'Credit Card' : 'ACH'}
                        </p>
                      </div>
                      {item.card_details.name != '' ? (
                        <>
                          <div className="flex justify-between items-center w-full mb-1">
                            <p className="text-sm">Name:</p>
                            <p className="text-body-color text-sm">{item.card_details.name}</p>
                          </div>
                          <div className="flex justify-between items-center w-full mb-1">
                            <p className="text-sm">Card Number:</p>
                            <p className="text-body-color text-sm">
                              XXXX XXXX XXXX {item.card_details.card_last_4_digit}
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="flex justify-between items-center w-full mb-1">
                            <p className="text-sm">Name:</p>
                            <p className="text-body-color text-sm">{item.ach_details.name}</p>
                          </div>
                          <div className="flex justify-between items-center w-full mb-1">
                            <p className="text-sm">Account Number:</p>
                            <p className="text-body-color text-sm">
                              XXXX XXXX XXXX {item.ach_details.account_last_4_digit}
                            </p>
                          </div>
                        </>
                      )}
                      {item.is_primary === true && (
                        <p className="text-button-primaryHover font-semibold text-sm mt-2">
                          Primary
                        </p>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <div className="pb-1 mt-3 flex flex-row justify-between rounded-lg items-center border ">
                  <div className="flex justify-center p-10">
                    <div className="text-md text-slate-500 mt-1 w-[300px]">
                      No Payment Method Available.
                    </div>
                  </div>
                </div>
              )}

              <div className="flex justify-between items-center border-b-[2px] pb-1 mt-3">
                <h6 className="text-body-color font-medium ">Billing Address</h6>
                {/* <button
              onClick={() => {
                _this.setEditPaymentModalOpen(true);
              }}
              className="bg-blue-500 text-green-700 px-2 py-1 rounded text-xs flex flex-row"
            >
              <AiOutlineSetting className="text-white" size={20} />
              <span className="font-medium text-sm ml-0.5 text-white">Edit</span>
            </button> */}
              </div>
              <div className="text-md text-slate-500 mt-1 w-[300px]">
                {customer?.billing_address?.address1 +
                  ', ' +
                  (customer?.billing_address?.address2
                    ? customer?.billing_address?.address2 + ', '
                    : '') +
                  customer?.billing_address?.city +
                  ', ' +
                  customer?.billing_address?.state +
                  ' ' +
                  customer?.billing_address?.zipcode +
                  ', ' +
                  customer?.billing_address?.country}
              </div>
            </Card>
            <Card className="mb-3 flex basis-2/3 mt-2">
              <div className="border-b-[3px] pb-2 flex flex-row justify-between items-center">
                <h3 className="text-body-color text-xl font-semibold">
                  Subscriptions ({customer?.subscription_groups?.length})
                </h3>
                {/* <button
              onClick={() => {
                _this.setAddNewSubscriptionModalOpen(true);
              }}
              className="bg-blue-500 text-green-700 px-2 py-1 rounded text-xs flex flex-row"
            >
              <BiPlus className="text-white" size={23} />
              <span className="font-medium text-base ml-0.5 text-white">Add</span>
            </button> */}
              </div>
              {customer?.subscription_groups?.map((subscription_group, index) => {
                const firstSubscription = subscription_group?.subscriptions[0];
                return (
                  <div key={index}>
                    <div className="flex flex-row justify-between items-center border-b-[2px] pb-2 mt-4">
                      <h4 className="text-body-color font-bold">Subscription - {index + 1}</h4>
                      <div className="font-semibold bg-teal-400 text-teal-700 text-sm rounded-md flex justify-center items-center py-1 px-2">
                        Next Invoice -{' '}
                        {moment(firstSubscription?.next_invoice_date).format('YYYY-MM-DD')}
                      </div>
                      {/* <button
                    onClick={() => {
                      _this?.setSubscriptionIdforPlan(firstSubscription?._id);
                      _this.setAddNewPlanModalOpen(true);
                    }}
                    className="bg-blue-500 text-green-700 px-2 py-1 rounded text-xs flex flex-row"
                  >
                    <h6 className="text-white text-sm">Add New Plan</h6>
                  </button> */}
                    </div>
                    <div className="flex flex-col border-b-[2px]">
                      <div className=" basis-1/2">
                        <div className="flex flex-row mt-3 text-sm text-body-color">
                          <div className="flex basis-1/2">
                            <span className="font-semibold mr-2">Store : </span>{' '}
                            {firstSubscription?.product_information.location}
                          </div>
                          <div className="flex basis-1/2">
                            <span className="font-semibold mr-2">Chargable : </span>{' '}
                            {firstSubscription?.product_information.chargable}
                          </div>
                        </div>
                        <div className="flex flex-row mt-1 text-sm text-body-color">
                          <div className="flex basis-1/2">
                            <span className="font-semibold mr-2">Right Serial : </span>{' '}
                            {firstSubscription?.product_information.left_serial || 'N/A'}
                          </div>
                          <div className="flex basis-1/2">
                            <span className="font-semibold mr-2">Right Serial : </span>{' '}
                            {firstSubscription?.product_information.right_serial || 'N/A'}
                          </div>
                        </div>
                        <div className="flex flex-row mt-1 text-sm text-body-color">
                          <div className="flex basis-1/2">
                            <span className="font-semibold mr-2">L Battery Size : </span>{' '}
                            {firstSubscription?.product_information.l_battery_size || 'N/A'}
                          </div>
                          <div className="flex basis-1/2">
                            <span className="font-semibold mr-2">R Battery Size : </span>{' '}
                            {firstSubscription?.product_information.r_battery_size || 'N/A'}
                          </div>
                        </div>
                      </div>
                      {/* <div className="font-semibold mt-4 text-body-color">Plans </div> */}
                      <div className=" mb-2 pb-2 mt-3">
                        {/* <div className="w-[100%]">
                          <div className="bg-blue-100 flex  justify-between border-t-[3px] p-1 ">
                            <h3 className="border-r text-lg">Plan Name</h3>
                            <h3 className=" border-r text-lg text-center  ">Price</h3>
                            <h3 className="border-r text-lg text-center ">Status</h3>
                            <h3 className=" text-lg text-center ">Action</h3>
                          </div> */}
                        <table className="w-full">
                          <thead>
                            <tr className="bg-blue-100">
                              <th>Plan Name</th>
                              <th>Price</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {subscription_group?.subscriptions?.map((subscription, subIndex) => {
                              return (
                                <tr key={subIndex}>
                                  <td>
                                    <div className="max-w-[10rem] md:max-w-none p-1 text-xs md:text-base border-r border-l-none break-words">
                                      {subscription?.plan_name}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="flex-1 border-r text-xs md:text-base text-center">
                                      ${subscription?.price}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="w-24 border-r text px-1 py-2 text-base flex justify-center items-center ">
                                      <div
                                        className={`${renderStatusColorClass(
                                          subscription?.status
                                        )} flex-1 font-semibold px-2 rounded  text-center`}
                                      >
                                        <span className="font-semibold text-sm">
                                          {subscription?.status}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="flex-1 text-sm border-r flex justify-center align-center">
                                      <button
                                        onClick={() => {
                                          _this.setSubscriptionDetails(subscription);
                                          _this.setSubscriptionDetailsModalOpen(true);
                                        }}
                                        className="bg-blue-500 text-green-700 p-1 rounded text-xs flex flex-row"
                                      >
                                        <h6 className="text-white text-xs">View Details</h6>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Card>
          </div>
          <Card className="mb-3 flex mr-2 mt-2">
            <h3 className="text-body-color text-xl font-semibold border-b-[3px] pb-2">Invoices</h3>
            <Table
              keyField="id"
              columns={invoice_table_columns}
              data={customer?.invoices}
              headerComponent={null}
              minRows={10}
            />
          </Card>
          <ViewSubscriptionDetails _this={_this} />
        </>
      )}
      <SetPrimaryPaymentModal _this={_this} />
      <AddNewPayment _this={_this} />
      <DocumentModel _this={_this} />
    </>
  );
};

export default Section;
