import {
  handleResponse,
  API,
  APIFORMDATA,
  getMiracleAppURL,
  getToken,
  processFormData
} from './utils';
import states from '../helpers/states.json';

const customer = {
  //  this two method will be used by customer itself. without loging in
  emailConfirmation: async (customer_id, allow) => {
    let response = null;
    try {
      response = await API.post('/v1/public/email-confirmation', { customer_id, allow });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  addNewPaymentByCustomer: async (data) => {
    // const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/public/add-payment-by-customer', data);
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  //  customer_id will be in route param.

  getCustomerById: async (customerId) => {
    try {
      const response = await fetch(
        `${getMiracleAppURL()}v1/api/myhspp.com/hspp_registration.php?action=fetch_patient&customerId=${customerId}`
      );
      const response_1 = await response.json();
      return response_1;
    } catch (err) {
      console.log('Error from server ' + err);
    }
  },
  getSerialNumberById: async (customerId) => {
    try {
      const response = await fetch(
        `${getMiracleAppURL()}v1/api/myhspp.com/hspp_registration.php?action=fetch_serial_number&customerId=${customerId}`
      );

      const response_1 = await response.json();
      return response_1;
    } catch (err) {
      console.log('Error from server ' + err);
    }
  },
  getLocations: async () => {
    const token = await getToken();
    try {
      const response = await API.get('/v1/location/fetch-location', {
        headers: { Authorization: 'Bearer: ' + token }
      });
      // const response_1 = await response.json();
      return response.data;
    } catch (err) {
      console.log('Error from server ' + err);
    }
  },
  getCountryStates: async () => {
    // return fetch(`${getMiracleAppURL()}hspp_registration.php?action=fetch_country_state`)
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .then((response) => {
    //     return response;
    //   })
    //   .catch((err) => {
    //     console.log('Error from server ' + err);
    //   });
    return states;
  },
  getPlansData: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get('/v1/customer/get-plans', {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getCustomerList: async (
    page = 0,
    limit = 100,
    payload = null,
    sortingField,
    sortingDirection
  ) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        `/v1/customer/get-customers`,
        { page, limit, payload, sortingField, sortingDirection },
        {
          params: {},
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getCustomerDetailsById: async (ID) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        '/v1/customer/get-customer-details-by-id',
        { hspp_id: ID },
        {
          params: {},
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getMyDetailsById: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get('/v1/customer/get-patient-page-details-by-id', {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  checkCustomerExists: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/customer/check-customer-exist', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  addNewPayment: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/customer/add-paymentmethod', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  selectPrimaryPaymentMethod: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/customer/select-primary-paymentmethod', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
      return handleResponse(response);
    } catch (error) {
      response = error;
    }
  },
  deletePaymentMethod: async (payment_id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/v1/customer/delete-payment-method?payment_id=${payment_id}`, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
      return handleResponse(response);
    } catch (error) {
      response = error;
    }
  },
  createCustomer: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/customer/create', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  updateCustomerDetails: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        '/v1/customer/edit',
        { key: data?.key, hspp_id: data?.hspp_id, data: data?.data },
        {
          params: {},
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  updateBillingAddress: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        '/v1/customer/edit-billing',
        { hspp_id: data?.hspp_id, data: data?.data },
        {
          params: {},
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  addPlans: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/customer/add-plan', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  addNote: async (hspp_id, note) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        '/v1/customer/add-note',
        { hspp_id: hspp_id, note: note },
        {
          params: {},
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  cancelSubscription: async (subscription_id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/v1/customer/cancel-subscription/${subscription_id}`, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  changeCustomerProfilePassword: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/customer/change-customer-profile-password', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  deleteCustomer: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/customer/delete-customer', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getDeletedCustomerList: async () => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get('/v1/customer/get-deleted-customers', {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getDeletedCustomerListBySearch: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(`/v1/customer/get-deleted-customers/search`, data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  editCustomerProfile: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(`/v1/customer/edit-profile`, data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  restoreCustomer: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(`/v1/customer/restore-customer`, data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  generateEmailCustomer: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/customer/generate-email', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  initiateRefund: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/customer/refund_payment', data, {
        params: {},
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  EditSubscription: async (data) => {
    // console.log('FinalData', data);
    const token = await getToken();
    let response = null;
    const structuredData = {
      location_name: data.location.label,
      location_id: data.location.value.locationId,
      franchise_name: data.location.value.franchiseName,
      franchise_id: data.location.value.franchiseId,
      location_group: data.location.value.locationGroup,
      chargable: data.chargable.value,
      l_battery_size: data.l_battery_size.value,
      r_battery_size: data.r_battery_size.value,
      right_serial: data.right_serial,
      left_serial: data.left_serial,
      id: data.id,
      customer: data.customer,
      newsletter: data.newsletter
    };
    try {
      response = await API.post('/v1/customer/update-customer-subscription', structuredData, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  GetDocuments: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/documents/get-documents', data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  SendDocuments: async (data, onUploadProgress) => {
    const token = await getToken();
    let response = null;
    const finalData = processFormData(data);
    try {
      response = await APIFORMDATA.post('/v1/documents/insert-document', finalData, {
        headers: { Authorization: 'Bearer: ' + token },
        onUploadProgress: onUploadProgress
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  AddCredits: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/credits/insert-credits', data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  GetCredits: async (data) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/credits/get-credits', data, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  getCustomerIDs: async (e) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post(
        '/v1/customer/get-customer-ids',
        { input: e },
        {
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  // searchTransactionIds: async (e) => {
  //   const token = await getToken();
  //   let response = null;
  //   try {
  //     response = await API.get(`/v1/customer/search-transaction-ids?input=${e}`, {
  //       headers: { Authorization: 'Bearer: ' + token }
  //     });
  //   } catch (e) {
  //     response = e;
  //   }
  //   return handleResponse(response);
  // },
  ExportCustomer: async (params) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.post('/v1/customer/export-customer', params, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },
  SendCustomerPassword: async (user_id) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(`/v1/customer/send-customer-password?user_id=${user_id}`, {
        headers: { Authorization: 'Bearer: ' + token }
      });
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  },

  OptOutFromMonthlyNewsletterEmail: async (customer_id, checked) => {
    const token = await getToken();
    let response = null;
    try {
      response = await API.get(
        `/v1/customer/opt-out-from-monthly-newsletter-email?customer_object_id=${customer_id}&checked=${checked}`,
        {
          headers: { Authorization: 'Bearer: ' + token }
        }
      );
    } catch (e) {
      response = e;
    }
    return handleResponse(response);
  }
};

export default customer;
