import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadingStart, loadingStop, logout } from 'redux/action';
import API from 'services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Body from './Body';

const Component = ({
  customer,
  is_password_changed,
  password_changed_greater_than_120_days,
  init
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [setPaswordModalOpen, setSetPaswordModalOpen] = useState(false);
  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const [password_details, setPassword_details] = useState({
    password: '',
    confirm_password: ''
  });

  const [edit_profile, setEdit_profile] = useState({
    first_name: '',
    last_name: '',
    mail: ''
  });

  useEffect(() => {
    if (!is_password_changed || password_changed_greater_than_120_days)
      setSetPaswordModalOpen(true);

    if (customer) {
      setEdit_profile((prev) => ({
        ...prev,
        first_name: customer.first_name,
        last_name: customer.last_name,
        mail: customer.email
      }));
    }
  }, [editProfileModalOpen, customer]);

  const changePassword = async () => {
    dispatch(loadingStart());
    await API.changeCustomerProfilePassword(password_details)
      .then((response) => {
        if (response) {
          toast.success('Password updated successfully. Login again with your new password');
          setSetPaswordModalOpen(false);
          dispatch(logout());
          navigate('/');
        }
      })
      .finally(() => dispatch(loadingStop()));
  };

  const UpdateProfile = async () => {
    if (!edit_profile.first_name || !edit_profile.last_name || !edit_profile.mail)
      return toast.error('All fields are mandatory');
    dispatch(loadingStart());
    await API.editCustomerProfile(edit_profile)
      .then((response) => {
        if (response) {
          toast.success('Profile has been updated successfully');
          setEditProfileModalOpen(false);
          init();
        }
      })
      .finally(() => dispatch(loadingStop()));
  };

  return (
    <>
      <Body
        _this={{
          customer,
          is_password_changed,
          changePassword,
          password_details,
          setPassword_details,
          setPaswordModalOpen,
          editProfileModalOpen,
          setEditProfileModalOpen,
          edit_profile,
          setEdit_profile,
          UpdateProfile
        }}
      />
    </>
  );
};

export default Component;
