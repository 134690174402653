import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { Card, Input, Button } from 'components';
import { Form } from 'react-bootstrap';
import { MdEdit } from 'react-icons/md';
import ButtonBTN from 'react-bootstrap/Button';
import { parseHsppId } from 'services/helpers';
import CheckPasswordChangeModal from './CheckPasswordChangeModal';
import EditProfileModal from './EditProfileModal';

const Body = ({ _this }) => {
  return (
    <>
      {_this.is_password_changed == false && (
        <Alert key={'danger'} variant={'danger'}>
          Welcome{' '}
          <strong>
            {_this.customer?.first_name} {_this.customer?.last_name}. Please set your password and
            login again
          </strong>{' '}
          to access the dashboard.
        </Alert>
      )}

      <Card background={'bg-cardbg1'}>
        <div className="flex flex-row justify-between">
          <h3 className="text-body-color text-xl md:text-2xl">Profile</h3>
          <div className="mr-10">
            <div className="flex flex-row items-center justify-between">
              <span className="bg-green-200 text-green-700 font-semibold px-3 py-1 rounded text-xs">
                <span className="font-semibold text-base">{_this.customer?.status}</span>
              </span>
            </div>
          </div>
        </div>
      </Card>
      <div className="flex flex-col md:flex-row">
        <Card className="mb-3 flex basis-1/2 mr-2 mt-2">
          <div className="pb-2 flex flex-row border-b-[3px] items-center justify-between">
            <h3 className="text-body-color text-xl font-semibold">
              Customer | {_this.customer?.first_name + ' ' + _this.customer?.last_name}
            </h3>
            <ButtonBTN
              onClick={() => _this.setEditProfileModalOpen(true)}
              className={`bg-button-primary`}
              variant="primary"
              id="button-addon3"
            >
              <MdEdit size={24} fill="white" />
            </ButtonBTN>{' '}
          </div>
          <h6 className="text-body-color font-medium border-b-[2px] pb-1 mt-3">Contact Details</h6>
          <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
            <h5 className="text-slate-500 text-sm">HSPP ID:</h5>
            <h5 className="text-slate-500 text-sm">{parseHsppId(_this.customer?._id)}</h5>
          </div>
          <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
            <h5 className="text-slate-500 text-sm">Patient ID:</h5>
            <h5 className="text-slate-500 text-sm">{_this.customer?.customer_id}</h5>
          </div>
          <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
            <h5 className="text-slate-500 text-sm">Name:</h5>
            <h5 className="text-slate-500 text-sm">
              {_this.customer?.first_name + ' ' + _this.customer?.last_name}
            </h5>
          </div>
          <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
            <h5 className="text-slate-500 text-sm">Phone:</h5>
            <h5 className="text-slate-500 text-sm">{_this.customer?.phone}</h5>
          </div>
          <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
            <h5 className="text-slate-500 text-sm">Email:</h5>
            <h5 className="text-slate-500 text-sm">{_this.customer?.email}</h5>
          </div>
          <div className="mt-3 flex flex-row justify-between items-center border-b-[1px] pb-2">
            <h5 className="text-slate-500 text-sm">Signature:</h5>
            <h5 className="text-slate-500 text-sm signature">
              {_this.customer?.signature?.signature}
            </h5>
          </div>
        </Card>
        <Card className="mb-3 flex basis-1/2 mt-2">
          <h3 className="text-body-color text-xl font-semibold border-b-[3px] pb-2">
            Change Password
          </h3>
          <h6 className="text-body-color font-medium border-b-[2px] pb-1 mt-3">Change Password</h6>
          <div className="text-md text-slate-500 mt-1">
            <Form className="bg-slate-100 mt-2 p-3 rounded">
              <Input
                label="Password"
                placeholder="@Password"
                type="password"
                autocomplete="off"
                required
                value={_this.password_details.password}
                onChange={(e) =>
                  _this.setPassword_details((prev) => ({
                    ...prev,
                    password: e.target.value
                  }))
                }
              />
              <Input
                label="Confirm Password"
                placeholder="@Confirm Password"
                type="password"
                autocomplete="off"
                required
                value={_this.password_details.confirm_password}
                onChange={(e) =>
                  _this.setPassword_details((prev) => ({
                    ...prev,
                    confirm_password: e.target.value
                  }))
                }
              />
            </Form>
            <div className="mt-3">
              <Button onClick={() => _this.changePassword()}>Update Password</Button>
            </div>
          </div>
        </Card>
      </div>
      <CheckPasswordChangeModal _this={_this} />
      <EditProfileModal _this={_this} />
    </>
  );
};

export default Body;
